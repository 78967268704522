<template>
  <div id="app">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        class="ui-view"
        transition-mode="out-in"
      ></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      class="ui-view"
      transition-mode="out-in"
    ></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
    // 判断进入的是那个端 跳入相应的路由 页面
    if (this._isMobile()) {
      //移动端
      window.location.href = "https://m.jiadivip.com/";
      // window.location.href = "http://192.168.1.103:8080/mobile/";
    } else {
      //pc端
      this.$router.replace("/");
      // window.location.href = "http://jiadivip.com/";
    }
  },
  methods: {
    //判断 是否进入的是移动端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.content {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
