//1.导入VueRouter
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router = new VueRouter({
  //tips:不想要 #（锚点）就添加下面代码
  mode: "hash",
  //4.配置路由的path和组件
  routes: [
    {
      path: "/",
      redirect: "home",
    },
    {
      path: "/home",
      name: "home",
      meta: {
        keepAlive: true, // 需要缓存
      },
      component: Home,
    },
    {
      path: "/new",
      name: "new",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/new.vue"),
    },
    {
      path: "/home1",
      name: "home1",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/home1.vue"),
    },
    // 新闻中心
    {
      path: "/newsCenter",
      name: "newsCenter",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/newsCenter.vue"),
    },
    // 新闻详情
    {
      path: "/newsDetail",
      name: "newsDetail",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/newsDetail.vue"),
    },
    // 新闻详情-写死
    {
      path: "/newsInfo",
      name: "newsInfo",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/newsInfo.vue"),
    },
    {
      path: "/newsInfoOne",
      name: "newsInfoOne",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/newsInfoOne.vue"),
    },
    {
      path: "/newsInfoTwo",
      name: "newsInfoTwo",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/newsInfoTwo.vue"),
    },
    {
      path: "/newsInfoThree",
      name: "newsInfoThree",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/newsInfoThree.vue"),
    },

    // 加入我们
    {
      path: "/joinUs",
      name: "joinUs",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/joinUs.vue"),
    },
    // 品牌故事
    {
      path: "/brandStory",
      name: "brandStory",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/brandStory.vue"),
    },
    // 联系我们
    {
      path: "/contactUs",
      name: "contactUs",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/contactUs.vue"),
    },
    // 服务流程
    {
      path: "/serviceProcess",
      name: "serviceProcess",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/serviceProcess.vue"),
    },
    // 商务团队
    {
      path: "/bussinessTeam",
      name: "bussinessTeam",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/bussinessTeam.vue"),
    },
    // 设计团队
    {
      path: "/designTeam",
      name: "designTeam",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/designTeam.vue"),
    },
    // 设计师详情
    {
      path: "/designDetail",
      name: "designDetail",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/designDetail.vue"),
    },
    // 精选案例
    {
      path: "/selectedCases",
      name: "selectedCases",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/selectedCases.vue"),
    },
    // 案例详情
    {
      path: "/caseDetail",
      name: "caseDetail",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/caseDetail.vue"),
    },
    {
      path: "/lunBo",
      name: "lunBo",
      meta: {
        keepAlive: false, // 不需要缓存
      },
      component: () => import("../views/lunBo.vue"),
    },
  ],
});
//  挂载路由守卫
// to 将访问哪一个路径
// from 代表从哪个路径跳转而来
// next 是一个函数,表示放行

// router.beforeEach((to, from, next) => {

//     // 路由跳转后，让页面回到顶部
//     document.body.scrollTop = 0;
//     document.documentElement.scrollTop = 0;
//     window.pageYOffset = 0;
//     //next() 放行 next('/login') 强制跳转
//     // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁!!
//     next();

// });
//5.导入路由实例
export default router;
