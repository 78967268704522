<template>
  <div class="demo">
    <video
      class="videoItems"
      width="664"
      height="470"
      controls
      :controlslist="videolists.controlslist"
      style="object-fit: cover"
      poster="https://jdqny.com/jdweb/shipinfengming.png"
      loop
    >
      <source src="https://jdqny.com/jdweb/xuanchuanshipin.mp4" />
    </video>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videolists: {
        autoplay: false, // 自动播放
        controls: "controls", //操作
        controlslist: "nodownload",
        /*
        1、nodownload: 不要下载按钮
        2、nofullscreen: 不要全屏按钮
        3、noremoteplayback: 不要远程回放
        4、disablePictureInPicture 不要画中画按钮
        5、noplaybackrate 不要播放速度按钮
        */
      }, //video配置
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.demo {
}
</style>
