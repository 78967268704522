import axios1 from "./config";

// banner
export function getBanner(params) {
  return axios1({
    url: "/web/banner",
    method: "get",
    params,
  });
}
// 新增 咨询留言
export function getAddMsg(data) {
  return axios1({
    url: "/web/addComment",
    method: "post",
    data,
  });
}
// 新闻中心
export function getNews(params) {
  return axios1({
    url: "/web/news",
    method: "get",
    params,
  });
}
// 新闻详情
export function getNewsDetail(params) {
  return axios1({
    url: "/web/info/news",
    method: "get",
    params,
  });
}
// 设计团队
export function getDesignTeam(params) {
  return axios1({
    url: "/web/design/team",
    method: "get",
    params,
  });
}
// 设计师详情
export function getDesignDetail(params) {
  return axios1({
    url: "/web/info//design/team",
    method: "get",
    params,
  });
}
// 招聘信息
export function getRecruit(params) {
  return axios1({
    url: "/web/recruit",
    method: "get",
    params,
  });
}
// 案例
export function getCase(params) {
  return axios1({
    url: "/web/cases",
    method: "get",
    params,
  });
}
// 案例 详情
export function getCaseDetail(params) {
  return axios1({
    url: "/web/info/case",
    method: "get",
    params,
  });
}
// 视频
export function getVideo(params) {
  return axios1({
    url: "/web/video",
    method: "get",
    params,
  });
}
// 商务团队
export function getSales(params) {
  return axios1({
    url: "/web/selectSales",
    method: "get",
    params,
  });
}
